import React from 'react'
import styles from './circular-progress.module.css'

type PropsType = {
    size?: React.CSSProperties['width'] | React.CSSProperties['height'],
    color?: React.CSSProperties['color']
}

function CircularProgress({color='#f00', size=20}:PropsType) {
    return (
        <span 
            className={styles.circular_progress_container} 
            role="progressbar" 
            style={{width: size, height: size, color}}>
            <svg className={styles.circular_progress_svg} viewBox="22 22 44 44">
                <circle className={styles.circular_progress_circle} cx="44" cy="44" r="20.2" fill="none" strokeWidth="3.6"></circle>
            </svg>
        </span>
    )
}

export default CircularProgress
