import {ReactComponent as InfoIcon} from './images/info-icon.svg'
import {ReactComponent as WarningIcon} from './images/warning-icon.svg'
import {ReactComponent as SuccessIcon} from './images/success-icon.svg'
import {ReactComponent as ErrorIcon} from './images/error-icon.svg'
import { ToastType } from '../../types/toast'
type PropsType = {
    type: ToastType['type'],
    className: string
}
function ToastIcon({type, className}: PropsType) {
    return (
        <>
            {type === 'info' && <InfoIcon className={className} />}
            {type === 'warning' && <WarningIcon className={className} />}
            {type === 'success' && <SuccessIcon className={className} />}
            {type === 'error' && <ErrorIcon className={className} />}
        </>
    )
}

export default ToastIcon
