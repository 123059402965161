import {AuthStateType, AuthActionType} from '../types/authTypes'

const initialState = {
    isAuth: false,
    token: localStorage.getItem('token') || '',
    user: null,
    loading: true
};

const authReducer = (state: AuthStateType = initialState , action: AuthActionType):AuthStateType => {
    switch (action.type) {
        case 'LOGIN':
        case 'USER_LOADED':
            return {...state, isAuth: true, ...action.payload};
        case 'LOGOUT':
            return {...state, isAuth: false, token: '', user: null};
        case 'auth/LOADING':
            return {...state, loading: action.payload};
        default:
            return state;
    }
}

export default authReducer