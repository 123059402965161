import React from 'react'
import styles from './loading.module.css'

function Loading() {
    return (
        <div className={styles.loader_container}>
            <div className={styles.loader}>
                <h1>Loading...</h1>
            </div>
        </div>
    )
}

export default Loading
