// import Button from "../components/Button";
// import TextField from "../components/TextField";


function Dashboard() {
    return (
        <div className="container">
            <h1 className="text-center">Dashboard</h1>
            {/* <TextField as="textarea" variant="underlined" error helperText='Error 404'  /><br />
            <TextField as="input" type="text" variant="filled" error helperText='Error 404'  />
            <Button variant='outlined' fullWidth>Button</Button> */}
        </div>
    )
}

export default Dashboard;