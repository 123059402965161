import {ReactComponent as IconRest} from '../../images/devmastery/logos/icon-rest.svg'


type PropsType = React.ComponentProps<'svg'>


export default ({
    style={},
    fill='#fff',
    width='auto',
    height='auto',
    ...rest
}: PropsType) => <IconRest fill={fill} {...rest} style={{width, height, ...style}} />
