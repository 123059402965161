import React from 'react';
import {useWindowSize, useOnClickOutside} from '../utils/customHooks';

import './InsideLayout.scss';
import Topbar from '../components/Topbar';
import Navbar from '../components/Navbar';

const NAVBAR_BREAKPOINT:number = 576;


type PropsType = {
  children: React.ReactNode
}

function InsideLayout({children}:PropsType) {
  const [open, setOpen] = React.useState(false);
    // Create a ref that we add to the element for which we want to detect outside clicks
    const navbarRef = React.useRef<HTMLDivElement>(null);
    // Call hook passing in the ref and a function to call on outside click
    useOnClickOutside(navbarRef, () => open ? closeNavbar() : null);
  const windowSize = useWindowSize();
  function openNavbar() {
    console.log('Enter');
    setOpen(true);
  }
  function closeNavbar() {
    console.log('Leave');
    setOpen(false);
  }
  return (
    <div className={`dashboard_wrapper ${open ? 'navbar_is_open' : ''}`}>
      <Topbar 
        windowSize={windowSize} 
        NAVBAR_BREAKPOINT={NAVBAR_BREAKPOINT} 
        openNavbar={openNavbar}
      />
      <Navbar 
        windowSize={windowSize} 
        NAVBAR_BREAKPOINT={NAVBAR_BREAKPOINT} 
        navbarRef={navbarRef}
        openNavbar={openNavbar}
        closeNavbar={closeNavbar}
      />
      <main>
          {children}
      </main>
    </div>
  );
}

export default InsideLayout;
