import { combineReducers } from 'redux'

import authReducer from './authReducer'
import feedbackReducer from './feedbackReducer'
import leadsReducer from './leadsReducer'

const rootReducer = combineReducers({
    auth: authReducer,
    feedback: feedbackReducer,
    leads: leadsReducer
})

export default rootReducer