import {FeedbackStateType, FeedbackAction} from '../types/feedbackTypes'
import { uid } from '../../utils/helpers'
import { ToastType } from '../../types/toast';

const initialState = {
    loading: false,
    error: '',
    toasts: []
};

const feedbackReducer = (state: FeedbackStateType = initialState , action: FeedbackAction):FeedbackStateType => {
    switch (action.type) {
        case 'feedback/LOADING':
            return {...state, loading: action.payload};
        case 'ERROR':
            return {...state, error: action.payload};
        case 'ADD_TOAST':
            const newToast:ToastType = {id: uid(), ...action.payload}
            return {...state, toasts: [...state.toasts, newToast]};
        case 'REMOVE_TOAST':
            return {...state, toasts: state.toasts.filter(toast => toast.id !== action.payload)};
        default:
            return state;
    }
}

export default feedbackReducer