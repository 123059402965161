import Certificate from '../components/Certificate';

function Certificates() {
    return (
        <div className="container">
            <h1 className="text-center">Certificates</h1>
            <Certificate />
        </div>
    )
}

export default Certificates;