import React, {useLayoutEffect, useRef} from 'react'
import './button.css'

type vrt = 'outlined' | 'contained'
type axe = 'x' | 'y'

type PropsType = {
    variant?: `${vrt}-transition-${axe}` | vrt,
    color?: 'primary' | 'secondary' | 'tertiary' | 'quaternary',
    ripple?: boolean,
    fullWidth?: boolean
} & Omit<React.ComponentProps<'button'>, 'color'>

const Button = ({
    variant='outlined',
    color,
    type='button',
    children,
    ripple=true,
    fullWidth=false,
    ...rest
}: PropsType) => {
    const btnRef = useRef<HTMLButtonElement>(null!)
    useLayoutEffect(() => {
        if (!ripple) return;
        btnRef.current.addEventListener('click', function(e){
            const target = e.target as HTMLButtonElement
            let x = e.clientX - target.offsetLeft
            let y = e.clientY - target.offsetTop

            let ripples = document.createElement('span')
            ripples.classList.add('ripple')
            ripples.style.left = x + 'px'
            ripples.style.top = y + 'px'
            this.appendChild(ripples)

            setTimeout(() => {
                ripples.remove()
            }, 1000);
        })
        return () => {}
    }, [ripple])
    return (
        <button ref={btnRef} className={`button ${variant} ${color} ${fullWidth ? 'w-100' : ''}`} type={type} {...rest} >
            {children}
        </button>
    )
}

export default Button;