import { Dispatch } from 'redux';
import { ToastType } from '../../types/toast';
import { LoadingActionType, ErrorActionType, AddToastActionType, RemoveToastActionType, AddToastType } from '../types/feedbackTypes';

export const requestStarted = ():LoadingActionType => ({
    type: 'feedback/LOADING',
    payload: true
});

export const requestFinished = ():LoadingActionType => ({
    type: 'feedback/LOADING',
    payload: false
});

export const addToast = (toast: AddToastType):AddToastActionType => ({
    type: 'ADD_TOAST',
    payload: toast
});

export const removeToast = (id: ToastType['id']):RemoveToastActionType => ({
    type: 'REMOVE_TOAST',
    payload: id
});


export const requestFailed = (err: any):ErrorActionType => {
    let errorMessage: string = err.message || 'Request failed'
    if (err && err.response && err.response.data && err.response.data.error && typeof(err.response.data.error) === 'string') {
        errorMessage = err.response.data.error
    }
    if (err && err.response && err.response.data && err.response.data.error && err.response.data.error.details) {
        errorMessage =  err.response.data.error.details[0] && err.response.data.error.details[0].message
    }
    return {
        type: 'ERROR',
        payload: errorMessage
    }
}