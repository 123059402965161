import { Dispatch } from 'redux';
import api from '../../utils/api'
import { setAuthTokenInAxiosHeaders, setAuthTokenInLS, removeAuthTokenFromAxiosHeaders, removeAuthTokenFromLS } from '../../utils/authToken';
import { AuthActionType, LoginActionType, LogoutActionType, LoadUserActionType, LoadingActionType, AuthStateType } from '../types/authTypes';
import { AddToastActionType, FeedbackAction } from '../types/feedbackTypes';
import { requestFailed, requestStarted, requestFinished, addToast } from '../actions/feedbackActionCreators';
import { ResponseStatusType } from '../types';



type LoginResponseDataType = {
    token: AuthStateType['token'],
    user: AuthStateType['user']
} & ResponseStatusType

type LoadUserResponseDataType = {
    user: AuthStateType['user']
}

export const login = (user: AuthStateType['user'], token: AuthStateType['token']):LoginActionType => {
    setAuthTokenInLS(token)
    setAuthTokenInAxiosHeaders(token)
    return {
        type: 'LOGIN',
        payload: {
            token,
            user
        }
    }
};

// export const logout = ():LogoutActionType => {
//     removeAuthTokenFromLS()
//     removeAuthTokenFromAxiosHeaders()
//     return { type: 'LOGOUT' }
// };
// OR
export const logout = () => {
    removeAuthTokenFromLS()
    removeAuthTokenFromAxiosHeaders()
    return (dispatch: Dispatch<LogoutActionType | AddToastActionType>) => {
        dispatch(addToast({
                type: 'info',
                message: 'Logout successfully !'
            }))
        dispatch({
            type: 'LOGOUT'
        })
    }
};

export const setLoading = (payload: AuthStateType['loading']):LoadingActionType => ({
    type: 'auth/LOADING',
    payload
});

export const requestLogin = (email: string, password: string) => {
    return async (dispatch: Dispatch<AuthActionType | FeedbackAction>) => {
        dispatch(requestStarted())
        try {
            const res = await api.post('/auth/login', { email, password })
            const { message, token, user }: LoginResponseDataType = res.data
            // alertSuccess(message)
            dispatch(login(user, token))
        } catch (err) {
            dispatch(requestFailed(err))
        } finally {
            dispatch(requestFinished())
        }
    }
}

export const userLoaded = (user: AuthStateType['user']):LoadUserActionType => {
    return {
        type: 'USER_LOADED',
        payload: {
            user
        }
    }
};

export const requestLoadUser = (token: AuthStateType['token']) => {
    setAuthTokenInAxiosHeaders(token)
    return async (dispatch: Dispatch<AuthActionType>) => {
        // dispatch(setLoading(true))
        try {
            const res = await api.get('/auth/load-user')
            const { user }: LoadUserResponseDataType = res.data
            // alertSuccess(message)
            dispatch(userLoaded(user))
        } catch (err) {
            // dispatch(requestFailed(err))
        } finally {
            dispatch(setLoading(false))
        }
    }
}