
import { Link, NavLink } from 'react-router-dom'

import Icon from './devmastery/Icon'
import IconRest from './devmastery/IconRest'

import { WindowSizeType } from '../utils/customHooks';

import { ReactComponent as LogoutIcon } from '../images/logout.svg'

type PropsType = {
    windowSize: WindowSizeType,
    NAVBAR_BREAKPOINT: number,
    navbarRef: React.RefObject<HTMLDivElement>,
    openNavbar: (e: React.MouseEvent<HTMLDivElement>) => void,
    closeNavbar: (e: React.MouseEvent<HTMLDivElement>) => void
}

function Navbar({
    windowSize,
    NAVBAR_BREAKPOINT,
    navbarRef,
    openNavbar,
    closeNavbar
}: PropsType) {
    return (
        <div
            ref={navbarRef}
            className={`navbar`}
            onMouseEnter={windowSize.width <= NAVBAR_BREAKPOINT ? undefined : openNavbar}
            onMouseLeave={windowSize.width <= NAVBAR_BREAKPOINT ? undefined : closeNavbar}
        >
            <div className="navbar-top">
                <Link className='navbar-top-link' to='/dashboard'>
                    <Icon height='75%' />
                    <IconRest height='75%' />
                </Link>
            </div>
            <div className="navbar-main">
                <ul className="nav_list">
                    <li className="nav_listitem">
                        <NavLink to="/dashboard">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z"></path></svg>
                            <span>Dashboard</span>
                        </NavLink>
                    </li>
                    <li className="nav_listitem">
                        <NavLink to="/leads">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" version="1.1" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg"><path d="M12 12.041v-0.825c1.102-0.621 2-2.168 2-3.716 0-2.485 0-4.5-3-4.5s-3 2.015-3 4.5c0 1.548 0.898 3.095 2 3.716v0.825c-3.392 0.277-6 1.944-6 3.959h14c0-2.015-2.608-3.682-6-3.959z"></path><path d="M5.112 12.427c0.864-0.565 1.939-0.994 3.122-1.256-0.235-0.278-0.449-0.588-0.633-0.922-0.475-0.863-0.726-1.813-0.726-2.748 0-1.344 0-2.614 0.478-3.653 0.464-1.008 1.299-1.633 2.488-1.867-0.264-1.195-0.968-1.98-2.841-1.98-3 0-3 2.015-3 4.5 0 1.548 0.898 3.095 2 3.716v0.825c-3.392 0.277-6 1.944-6 3.959h4.359c0.227-0.202 0.478-0.393 0.753-0.573z"></path></svg>
                            <span>Leads</span>
                        </NavLink>
                    </li>
                    <li className="nav_listitem">
                        <NavLink to="/certificates">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zM8 7v2h8V7H8zm0 4v2h8v-2H8zm0 4v2h8v-2H8z"></path></g></svg>
                            <span>Certificates</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
            <div className="navbar-bottom">
                <ul className="nav_list">
                    <li className="nav_listitem">
                        <div className="as_anchor">
                            <LogoutIcon style={{ transform: 'rotate(180deg)' }} />
                            <span>Logout</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar;