import React, {useEffect, useRef} from 'react'

import './text-field.css'

type CommonPropsType = {
    variant?: 'underlined' | 'filled' | 'outlined' | 'bordered',
    label?: string,
    error?: boolean,
    helperText?: string,
    fullWidth?: boolean,
    onChange?: (e: React.ChangeEvent<any>) => void,
} & Pick<React.ComponentProps<'input' | 'textarea'>, 'name' | 'id' | 'value'>

type InputPropsType = CommonPropsType & {
    as?: 'input',
    type?: 'date' | 'datetime-local' | 'email' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week',
    rows?: never
}

type TextAreaPropsType = CommonPropsType & {
    as?: 'textarea',
    type?: never
} & Pick<React.ComponentProps<'textarea'>, 'rows'>

type PropsType = InputPropsType | TextAreaPropsType

const TextField = ({
    variant='filled',
    label="Enter something",
    error=false,
    helperText='Helper text',
    fullWidth=false,
    as='input',
    type='text',
    ...rest
}: PropsType) => {
    const textFieldRef = useRef<any>(null!)
    const Component = as
    useEffect(() => {
        const handleBlur = (e: Event) => {
            const target = e.target as (HTMLInputElement | HTMLTextAreaElement)
            if(target.value) {
              target.classList.add('dirty')
            } else {
              target.classList.remove('dirty')
            }
        }
        textFieldRef.current.addEventListener('blur', handleBlur)
        return () => {}
    }, [])
    return (
        <label className={`custom-field ${variant} ${fullWidth ? 'w-100' : ''}`}>
            <Component ref={textFieldRef} className="text-field" type={type} placeholder="&nbsp;" {...rest} />
            <span className="placeholder">{label}</span>
            {variant === 'bordered' && <span className="border"></span>}
            {error && <span className="error-message" aria-live="polite">{helperText}</span>}
        </label>
    )
}

export default TextField;