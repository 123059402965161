import { Outlet, Navigate, useLocation } from 'react-router-dom';
import Loading from '../components/Loading';

import InsideLayout from '../layouts/InsideLayout';

import { useAppSelector } from '../redux/hooks';

function PrivateRoute() {
    const { isAuth, loading } = useAppSelector((state) => state.auth);
    const location = useLocation();
    return (
        loading
            ? <Loading />
            : isAuth
                ? <InsideLayout><Outlet /></InsideLayout>
                : <Navigate to='/login' state={{ from: location }} />

    )
}

export default PrivateRoute;