import React from 'react'
import styles from './lead-list.module.css'
import { Lead } from '../../types/lead';
import LeadDetails from '../LeadDetails';

type Props = {
    list: Lead[];
}

function LeadList({ list }: Props) {
    return (
        <div className={styles.container}>
            {
                list.map(lead => <LeadDetails key={lead._id} lead={lead} />)
            }
        </div>
    )
}

export default LeadList
