import { useAppSelector } from "../../redux/hooks";
import Toast from "./Toast"
import './toasts.css'

const Toasts = () => {
    const { toasts } = useAppSelector((state) => state.feedback);

    return (
        <div className="toast_container">
            {
                toasts.map(toast => <Toast key={toast.id} toast={toast} />)
            }
        </div>
    )
}

export default Toasts
