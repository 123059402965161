
import {ReactComponent as Icon} from '../../images/devmastery/logos/icon.svg'


type PropsType = React.ComponentProps<'svg'>


export default ({
    style={},
    fill='#fff', 
    width='auto', 
    height='auto', 
    ...rest
}: PropsType) => <Icon fill={fill} {...rest} style={{width, height, ...style}}  />
