import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import PrivateRoute from './routes/PrivateRoute';
import PublicRoute from './routes/PublicRoute';

import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Certificates from './pages/Certificates';

import './App.css';
import { useAppSelector, useAppDispatch } from "./redux/hooks";
import { requestLoadUser } from './redux/actions/authActionCreators';
import Toasts from './components/Toasts';
import Leads from './pages/Leads';

function App() {
  const { isAuth, token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(requestLoadUser(token))
    return () => { }
  }, [])

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to={isAuth ? '/dashboard' : '/login'} />} />
          <Route element={<PrivateRoute />}>
            <Route path='/dashboard' element={<Dashboard />} />
            <Route path='/certificates' element={<Certificates />} />
            <Route path='/leads' element={<Leads />} />
          </Route>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>

        </Routes>
        <Toasts />
      </div>
    </Router>
  );
}

export default App;
