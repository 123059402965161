import React from 'react'
import './dropdown.css'

type PropsType = {
    dropdownRef: React.Ref<HTMLDivElement>,
    open: boolean,
    children: React.ReactNode[]
}

function Dropdown ({dropdownRef, open, children}: PropsType) {
    return (
      <div ref={dropdownRef} className={`dropdown ${open ? 'active' : ''}`}>
        {children[0]}
        <div className="dropdown-menu information-grid">
        {children[1]}
        </div>
      </div>
    )
  }

export default Dropdown
