import { Navigate, useLocation, Outlet } from 'react-router-dom';
import Loading from '../components/Loading';

import OutsideLayout from '../layouts/OutsideLayout';

import { useAppSelector } from '../redux/hooks';

function PublicRoute() {
    const { isAuth, loading } = useAppSelector((state) => state.auth);
    const location = useLocation();
    return (
        loading
            ? <Loading />
            : isAuth
                ? <Navigate to='/dashboard' state={{ from: location }} />
                : <OutsideLayout><Outlet /></OutsideLayout>
    )
}

export default PublicRoute;