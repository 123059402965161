import { useState, useEffect } from "react"
import { useAppDispatch } from "../../redux/hooks";
import { removeToast } from "../../redux/actions/feedbackActionCreators";
import { ToastType } from "../../types/toast"
import ToastIcon from "./ToastIcon";

const numberSec = 1;

type PropsType = {
    toast: ToastType
}

const Toast = ({ toast: { id, type, message } }: PropsType) => {
    const [show, toggleShow] = useState(false)
    const dispatch = useAppDispatch();
    let timeout1: number, timeout2: number
    const execute = () => {
        toggleShow(false)
        return window.setTimeout(() => {
            dispatch(removeToast(id))
        }, numberSec * 1000);
    }
    const handleClick = () => {
        timeout2 = execute()
    }
    useEffect(() => {
        setTimeout(() => {
            toggleShow(true)
        }, 100);
        timeout1 = window.setTimeout(() => {
            timeout2 = execute()
        }, 5000);
        return () => {
            clearTimeout(timeout1)
            clearTimeout(timeout2)
        }
    }, [])
    return (
        <div className={`toast ${type} ${show ? 'show' : ''}`} style={{ transitionDuration: `${numberSec}s` }}>
            <div className='toast_body'>
                <ToastIcon className='toast_body_icon' type={type} />
                <p>{message}</p>
            </div>
            <span onClick={handleClick} style={{ cursor: 'pointer' }}>&#10005;</span>
        </div>
    )
}

export default Toast