export function formatDate(s:string|Date):string {
    const date = new Date(s)
    
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${day}/${month}/${year}`
}

export function formatTime(s:string|Date):string {
    const date = new Date(s)

    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    return `${hours}:${minutes}`
}