
import {ReactComponent as LogoNavbar} from '../../images/devmastery/logos/logo-navbar.svg'


type PropsType = React.ComponentProps<'svg'>


export default ({
    style={},
    fill='#fff', 
    width='auto', 
    height='auto', 
    ...rest
}: PropsType) => <LogoNavbar fill={fill} {...rest} style={{width, height, ...style}}  />
