
import './OutsideLayout.scss';

type PropsType = {
  children: React.ReactNode
}

function OutsideLayout({children}:PropsType) {

  return (
      <main>
          {children}
      </main>
  );
}

export default OutsideLayout;
