import {useState} from 'react'
import Button from '../components/Button'
import CircularProgress from '../components/CircularProgress'
import Icon from '../components/devmastery/Icon'
import TextField from '../components/TextField'
import { requestLogin } from '../redux/actions/authActionCreators'
import { useAppDispatch, useAppSelector } from '../redux/hooks'

import styles from './login.module.css'

function Login() {
    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    })
    const {loading} = useAppSelector((state) => state.feedback);
    const dispatch = useAppDispatch()

    const handleChange = (e:React.ChangeEvent) => {
        const target = e.target as (HTMLInputElement | HTMLTextAreaElement)
        setLoginData(prev => ({
            ...prev,
            [target.name]: target.value
        }))
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (loading) return;
        console.log('submit');
        dispatch(requestLogin(loginData.email, loginData.password));
    }
    return (
        <div className={styles.login_container}>
            <div className={styles.login_card}>
                <h1 className='text-center'>Welcome</h1>
                <Icon height={55} fill='#000' style={{margin: '26px 0 48px'}} />
                <form onSubmit={handleSubmit}>
                    <TextField 
                        variant='outlined' 
                        label='Email' 
                        name='email' 
                        onChange={handleChange} 
                        fullWidth 
                    />
                    <TextField 
                        type="password" 
                        variant='outlined' 
                        label='Password' 
                        name='password' 
                        onChange={handleChange} 
                        fullWidth 
                    />
                    <Button disabled={loading} type='submit' fullWidth style={{margin: '50px 0'}}>
                        {loading ? <CircularProgress size={18} color='inherit' /> : 'Login'}
                    </Button>
                    
                </form>
            </div>
        </div>
    )
}

export default Login;