import React, { useState, useEffect } from "react";


export type WindowSizeType = {
  width: number,
  height: number
}

// useWindowSize Hook
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSizeType>({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}


// useOnClickOutside Hook
export function useOnClickOutside(ref:React.RefObject<HTMLElement>, handler: (event: MouseEvent | TouchEvent) => void) {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}



// useOnClickOutside2 Hook
export function useOnClickOutside2(openerElementRef: React.RefObject<HTMLElement>, dropdownRef: React.RefObject<HTMLElement>) {
  const [open, setOpen] = useState(false);
  useEffect(
    () => {

      const listener = (e:MouseEvent) => {
        const target = e.target as Node
        const isDropdownOpener = target.isEqualNode(openerElementRef.current)
        
        if (!isDropdownOpener && dropdownRef.current!.contains(target)) return
        
        let currentDropdown: HTMLElement | null
        if (isDropdownOpener) {
          currentDropdown = dropdownRef.current
          // currentDropdown.classList.toggle("active")
          setOpen(prev => !prev)
        }
      
        document.querySelectorAll(".dropdown.active").forEach(dropdown => {
          if (dropdown === currentDropdown) return
          // dropdown.classList.remove("active")
          setOpen(false)
        })
      }
      document.addEventListener("click", listener)
      return () => {
        document.removeEventListener("click", listener);
      };
    },[openerElementRef, dropdownRef]
  );
  return [open];
}
