import * as React from "react"
import { useReactToPrint } from "react-to-print"
import QRCode from 'qrcode.react'

import { CertificateType } from '../types/certificate'

import tunisiaLogo from '../images/tunisia-logo.svg'
import award from '../images/award.svg'
import signature from '../images/az-signature.png'
import cachetDevMastery from '../images/cachet-devmastery.png'

import './certificate.css'
import TextField from "./TextField"
import Button from "./Button"
import LogoNavbar from "./devmastery/LogoNavbar"

const Certificate = () => {
    const [certificate, setCertificate] = React.useState<CertificateType>({} as CertificateType);
    const componentRef = React.useRef(null);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, []);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: "AwesomeFileName",
        removeAfterPrint: true
    });

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handlePrint()
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCertificate(prevUser => ({
            ...prevUser,
            [e.target.name]: e.target.value
        }))
    }

    React.useEffect(() => {
        setCertificate({
            _id: '123456789',
            nin: '11069264',
            firstName: 'Yassine',
            lastName: 'Zribi',
            track: 'DÉVELOPPEMENT WEB FULLSTACK JAVASCRIPT',
            trainingStart: '06/09/2021',
            trainingEnd: '31/12/2021'
        })
        return () => {
            // cleanup
        }
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr))', padding: '15px 0', gridGap: '0 30px', justifyItems: 'center', alignItems: 'center' }}>
                    {(Object.keys(certificate) as Array<keyof typeof certificate>).map((field, i) => (
                        <TextField key={i} id={field} value={certificate[field]} onChange={handleChange} name={field} label={field} fullWidth />
                    ))}
                    <Button type='submit' fullWidth>Print Certificate</Button>
                </div>
            </form>
            <div style={{overflow: 'auto'}}>
                <div ref={componentRef} className="certificate">
                    <style type="text/css" media="print">
                        {`
                    @page { 
                        size: A4 landscape; 
                        margin: 8px 8px 8px 8px;
                        height: 210mm;
                        width: 297mm;
                    }
                    .certificate {
                        height: 100vh;
                        width: 100%;
                    }
                    .certificate_container {
                        height: 100%;
                        width: 100%;
                    }
                    `}
                    </style>
                    <div className="certificate_container">
                        <div className="icon-top"><img src={award} height="100%" alt='award' /></div>
                        <img src={cachetDevMastery} alt="cachet-devmastery" style={{ height: 140, position: 'absolute', bottom: 30, right: "4.925cm" }} />
                        <div className="certif_top">
                            <LogoNavbar width={275} fill='#000' />
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 3, marginBottom: 3 }}>
                                    <img src={tunisiaLogo} height={18} alt='tn-logo' />
                                    <h4>République Tunisienne</h4>
                                </div>
                                <h6 style={{ fontSize: 11 }}>Ministère de la Formation Professionnelle et de l'Emploi</h6>
                            </div>
                        </div>
                        <div className="certif_main">
                            <h2 style={{ fontSize: 56, fontWeight: 700, color: '#303865' }}>CERTIFICAT</h2>
                            <h2 style={{ fontSize: 27, fontWeight: 700, color: '#303865', position: 'relative', bottom: 6 }}>DE RÉUSSITE</h2>
                            <h3 style={{ fontSize: 32, margin: '20px 0' }} className="student_name" >{certificate.firstName} {certificate.lastName?.toUpperCase()}</h3>
                            <p className="parag">titulaire de la carte d'identité nationale n° {certificate.nin} a validé avec succès et obtenu le certificat de formation en</p>
                            <h2 className="certif_track">{certificate.track}</h2>
                            <p className="parag_date">durant la période du <span className="certif_date">{certificate.trainingStart}</span> au <span className="certif_date">{certificate.trainingEnd}</span></p>
                        </div>
                        <div className="certif_bottom">
                            <span>Agréé par l'État sous le n° <span>61-410-21</span></span>
                            <div style={{ display: 'flex', alignItems: 'flex-end', gap: 15 }}>
                                <img src={signature} alt="signature" height={40} />
                                <div className="qr">
                                    <QRCode
                                        value={certificate._id || ''}
                                        renderAs='svg'
                                        bgColor="white"
                                        fgColor="black"
                                        size={80}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Certificate;