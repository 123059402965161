import { useRef } from 'react'
import { Link } from 'react-router-dom'

import Icon from './devmastery/Icon'
import IconRest from './devmastery/IconRest'

import { logout } from '../redux/actions/authActionCreators'
import { WindowSizeType, useOnClickOutside2 } from '../utils/customHooks';
import Dropdown from './Dropdown'

import {ReactComponent as MenuUnfoldIcon} from '../images/menu-unfold.svg'
import {ReactComponent as ThreeDotsVerticalIcon} from '../images/three-dots-vertical.svg'
import {ReactComponent as LogoutIcon} from '../images/logout.svg'
import { useAppDispatch } from '../redux/hooks'

type DropdownItemPropsType = {
    to?: string,
    title: React.ReactNode,
    icon: {
        component: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
        iconProps?: React.SVGProps<SVGSVGElement>
    },
    onClick?: () => void,
    open: boolean
}
// style={{ transform: 'rotate(180deg)' }}
const DropdownItem = ({to, onClick, title, icon: {component: Icon, iconProps}, open}: DropdownItemPropsType) => {
    const content = (
        <div style={{display: 'grid', gridTemplateColumns: '30px auto', alignItems: 'center'}}>
            <Icon {...iconProps} />
            <span>{title}</span>
        </div>
    );
    const accessibility = open? {} : {tabIndex: -1}
    return to 
            ? <Link to={to} className="link" {...accessibility}>{content}</Link> 
            : <button onClick={onClick} className="link">{content}</button>
}

type DropdownContentPropsType = {
    open: boolean
}

const DropdownContent = ({open}:DropdownContentPropsType) => {
    const dispatch = useAppDispatch();
    const handleClick = () => {
        console.log('logout');
        dispatch(logout());
    }
    return (
            <div className="dropdown-items">
                <DropdownItem 
                    to='/dashboard' 
                    title='Dashboard' 
                    icon={{
                        component: MenuUnfoldIcon, 
                        iconProps: {fontSize: 20}
                    }}
                    open={open} 
                />
                <DropdownItem 
                    onClick={handleClick} 
                    title='Logout' 
                    icon={{
                        component: LogoutIcon, 
                        iconProps: {width: '1.4em'}
                    }}
                    open={open} 
                />
            </div>
    )
}

type PropsType = {
    windowSize: WindowSizeType,
    NAVBAR_BREAKPOINT: number,
    openNavbar: (e: React.MouseEvent<SVGSVGElement>) => void
}

function Topbar({ windowSize, NAVBAR_BREAKPOINT, openNavbar }: PropsType) {
    const dropdownRef = useRef(null);
    const openerDropdownRef = useRef(null);
    const [open] = useOnClickOutside2(openerDropdownRef, dropdownRef);

    return (
        <div className="topbar">
            <div className="topbar_left">
                {windowSize.width <= NAVBAR_BREAKPOINT && (
                    <MenuUnfoldIcon style={{ marginRight: 10, cursor: 'pointer' }} onClick={openNavbar} height="50%" width="50%" />
                )}
                <Link to='/dashboard' style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                    {(windowSize.width <= NAVBAR_BREAKPOINT) && <Icon height='75%' />}
                    <IconRest height='75%' style={{ position: 'relative', right: !(windowSize.width <= NAVBAR_BREAKPOINT) ? 6 : 0 }} />
                </Link>
            </div>
            <div className="topbar_right">
                <Dropdown dropdownRef={dropdownRef} open={open}>
                    <ThreeDotsVerticalIcon className="pointer" ref={openerDropdownRef} width="1.4em" height="1.4em" />
                    <DropdownContent open={open} />
                </Dropdown>
            </div>
        </div>
    )
}

export default Topbar;