import { Dispatch } from "redux"
import { FetchLeadsResponse, LeadsAction, SetLeadsAction, UpdateLeadAction, UpdateLeadData, UpdateLeadResponse } from "../types/leadsTypes"
import { addToast, requestFailed, requestFinished, requestStarted } from "./feedbackActionCreators"
import { FeedbackAction } from "../types/feedbackTypes"
import api from "../../utils/api"
import { Lead } from "../../types/lead"

const setLeads = (payload: FetchLeadsResponse) : SetLeadsAction => {
    return {type: 'SET_LEADS', payload};
}

const updateLead = (payload: Lead) : UpdateLeadAction => {
    return {type: 'UPDATE_LEAD', payload};
}

export const requestFetchLeads = (page: string|number) => {
    return async (dispatch: Dispatch<LeadsAction | FeedbackAction>) => {
        dispatch(requestStarted())
        try {
            const res = await api.get<FetchLeadsResponse>('/leads', { params: { page } })
            dispatch(setLeads(res.data))
        } catch (err) {
            dispatch(requestFailed(err))
        } finally {
            dispatch(requestFinished())
        }
    }
}

export const requestUpdateLead = (leadId: string, data: UpdateLeadData, setRequestLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
    return async (dispatch: Dispatch<LeadsAction | FeedbackAction>) => {
        setRequestLoading(true)
        try {
            const res = await api.patch<UpdateLeadResponse>(`/leads/${leadId}`, data)
            dispatch(updateLead(res.data.lead))
            dispatch(addToast({
                type: 'info',
                message: res.data.message
            }))
        } catch (err) {
            dispatch(requestFailed(err))
        } finally {
            setRequestLoading(false)
        }
    }
}