import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { requestFetchLeads } from '../redux/actions/leadsActionCreators';
import CircularProgress from '../components/CircularProgress';
import LeadList from '../components/LeadList';
import Button from '../components/Button';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Leads() {
    const { list, page, nbPages } = useAppSelector((state) => state.leads);
    const { loading } = useAppSelector((state) => state.feedback);
    const dispatch = useAppDispatch();

    let [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        dispatch(requestFetchLeads(searchParams.get("page") || "1"))
    }, [searchParams]);

    return (
        <div className="container">
            <h1 className="text-center">Leads</h1>
            <LeadsForm setSearchParams={setSearchParams} />
            {
                loading
                    ? <div style={{ textAlign: 'center', marginTop: 50 }}><CircularProgress size={30} color='inherit' /></div>
                    : (
                        page > nbPages
                            ? <div>No leads ! Go to <Link to="/leads">page 1</Link></div>
                            : <LeadList list={list} />
                    )
            }
        </div>
    )
}

export default Leads;


function LeadsForm({ setSearchParams }: { setSearchParams: SetURLSearchParams }) {
    const { page, skip, limit, nbLeads, nbPages } = useAppSelector((state) => state.leads);
    const { loading } = useAppSelector((state) => state.feedback);

    function nextPage() {
        setSearchParams({ page: String(page + 1) })
    }

    function previousPage() {
        setSearchParams({ page: String(page - 1) })
    }

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 10, marginBottom: 5 }}>
            <div>
                {
                    page > nbPages
                        ? `0 - 0 of ${nbLeads}`
                        : `${(loading && nbLeads === 0) ? 0 : skip + 1} - ${skip + limit > nbLeads ? nbLeads : skip + limit} of ${nbLeads}`
                }
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
                <Button onClick={previousPage} color='secondary' disabled={loading || page <= 1 || page > nbPages} style={{ padding: 0, minHeight: 'auto' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="1em" width="1em" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </Button>
                <Button onClick={nextPage} color='secondary' disabled={loading || page >= nbPages} style={{ padding: 0, minHeight: 'auto' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" height="1em" width="1em" strokeWidth="1.5" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </Button>
            </div>
        </div>
    )
}