import {AuthStateType, AuthActionType} from '../types/authTypes'
import { LeadsAction, LeadsState } from '../types/leadsTypes';

const initialState: LeadsState = {
    list: [],
    page: 1,
    limit: 5,
    skip: 0,
    nbPages: 1,
    nbLeads: 0
};

const leadsReducer = (state: LeadsState = initialState , action: LeadsAction): LeadsState => {
    switch (action.type) {
        case 'SET_LEADS':
            return {...state, ...action.payload};
        case 'UPDATE_LEAD':
            const list = state.list.map(lead => lead._id === action.payload._id ? action.payload : lead)
            return {...state, list};
        default:
            return state;
    }
}

export default leadsReducer