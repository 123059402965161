import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import App from './App';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
import './fonts/montserrat/Montserrat-Light.ttf';
import './fonts/montserrat/Montserrat-Regular.ttf';
import './fonts/montserrat/Montserrat-Italic.ttf';
import './fonts/montserrat/Montserrat-Bold.ttf';
import './fonts/montserrat/Montserrat-Black.ttf';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store} >
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
