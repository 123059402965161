import { AuthStateType } from '../redux/types/authTypes';
import api from './api';

// store our JWT in LS and set axios headers if we do have a token
export const setAuthTokenInAxiosHeaders = (token: AuthStateType['token']) => {
    api.defaults.headers.common['x-auth-token'] = token;
};
export const setAuthTokenInLS = (token: AuthStateType['token']) => {
    localStorage.setItem('token', token);
};

export const removeAuthTokenFromAxiosHeaders = () => {
    delete api.defaults.headers.common['x-auth-token'];
};
export const removeAuthTokenFromLS = () => {
    localStorage.removeItem('token');
};
